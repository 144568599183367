import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const EffectiveCommunication = ({ location }) => {
  const title =
    "Twitterの効果的なコミュニケーション方法とは？ 誰でも実践可能な顧客との付き合い方をレクチャー"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterでの顧客との効果的なコミュニケーション方法とは | 公式アカウント運用担当者が気をつけるべきポイント"
          pagedesc="店舗やブランドのファンづくりに最適なTwitterで、公式アカウント担当者がどのようなやりとりをしていくべきか解説します。フォロワー増加に最適なキャンペーンなどTwitter運用のポイントをまとめました。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/effective-communication.jpg"
          pagepath="/blog/effective-communication"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は6分で読むことができます
            </div>
            <h1>
              Twitterの効果的なコミュニケーション方法とは？
              誰でも実践可能な顧客との付き合い方をレクチャー
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2021年11月12日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ハイタッチしている男女"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/effective-communication.jpg"
                className="w-full"
              />
            </figure>
            <p>こんにちは、PARKLoT マーケティング担当の島田旬子です！</p>
            <h2>フォロワーとのコミュニケーション、とれてますか？</h2>
            <p>
              Twitterキャンペーンで重要な「参加者とのコミュニケーション」。
              <br />
              キャンペーンの開催で一気にフォロワーを獲得し、それをきっかけにコミュニケーションを取ることで、店舗・ブランドのファンを増やすことができます。
            </p>
            <p>
              しかし、Twitterでのコミュニケーション方法について、どうすればいいか分からない方も多いのではないでしょうか。「中の人」（運用担当者）には、TANITA社やシャープ社のようなユーモアが必要だと感じている方もいらっしゃると思います。
            </p>
            <h2>ファン化につながるTwitter運用、カギはコミュニケーション</h2>
            <p>
              店舗・企業のファンを増やすことは、売上アップに欠かせないLTV（ライフ・タイム・バリュー）を高めることにつながります。
            </p>
            <p>
              そこで今回は、誰でもすぐに実践できる「ユーザーとのコミュニケーション方法」について、ご紹介します。
            </p>
            <p>
              この記事が、Twitterを使ったコミュニケーションのお役に立てれば幸いです。
            </p>
            <p>特に次のような人におすすめです！</p>
            <ul className="blog__border-list">
              <li>店舗・企業の公式アカウント運用担当者</li>
              <li>顧客とのコミュニケーション方法について悩んでいる方</li>
              <li>TANITA社やシャープ社のようなSNS運営は難しいと感じている方</li>
              <br />
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterで簡単にコミュニケーションが取れる方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        1. エゴサーチで投稿を見つけて「引用リツイート」
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        2. メッセージが難しいときは「リツイート」
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        3. フォロワーには「フォロー返し」
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    コミュニケーションの内容はどうすれば？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        1.Twitterでも基本は「定期的なあいさつ」
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        2.商品紹介は多くても1日に1回まで
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        3.ユーザーの役に立つ投稿を心掛ける
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        4.言葉遣いはケースバイケース
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    キャンペーンで参加者とコミュニケーションする
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        Twitterプレゼント企画でまずは1万フォロワーを目指そう
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        プレゼント企画はユーザーとの会話のきっかけづくりにも効果的
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id13" className="toc__link">
                    Twitterキャンペーンを低コストで始める方法
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">Twitterで簡単にコミュニケーションが取れる方法 </h2>
              <p>
                Twitterでのコミュニケーションを、デジタルやインターネットだと区別してはいけません。
                <br />
                リアルな店舗と同じように、そこにお客様がいると考えるようにしましょう。メンションやコメントは「お客様に話しかけられた」と捉えて接客することが重要です。
              </p>
              <p>
                Twitterにおける具体的なコミュニケーション方法は次の3つです。
              </p>
              <h3 id="id2">1. エゴサーチで投稿を見つけて「引用リツイート」</h3>
              <p>
                まず最初にするべきことは、自社・自店舗、あるいは自社の商品・サービスについてエゴサーチをすることです。Twitter上で検索して、出来るだけポジティブな投稿を探します。
              </p>
              <p>
                エゴサーチには
                TweetDeck※を利用するのが便利です。自社名or商品名orサービス名などで検索します。
                <br />
                ポジティブな投稿を見つけたら、短い言葉でもいいので積極的に感謝の気持ちをツイートします。その際には元の投稿が表示される「引用リツイート」がおすすめです。
              </p>
              <p>
                批判的な投稿については、改善が必要な内容以外は個別に対応しなくて構いません。肯定的なコミュニケーションにつながるポジティブな内容に注目するようにしましょう。
              </p>
              <p>
                <small>
                  ※TweetDeck……Twitterの公式クライアント・アプリケーションの一つ。ソーシャルメディアの友達や
                  <br />
                  話題を割り当てて分類することで、混乱を起こさずに一覧表示できる。
                </small>
              </p>
              <h3 id="id3">2. メッセージが難しいときは「リツイート」</h3>
              <p>
                エゴサーチで見つけた投稿のなかには、どんな反応をすればいいのか分からない場合もあります。そんなときには「リツイート」するようにしましょう。
              </p>
              <p>
                企業の公式Twitterアカウントからのリツイートについて、「9割以上のユーザーが好意的に捉えている」という調査結果もあります。メッセージ付きの「引用リツイート」と使い分けることで、コミュニケーションの幅が広がります。
              </p>
              <h3 id="id4">3. フォロワーには「フォロー返し」</h3>
              <p>
                「いいね!」やポジティブなコメント、新たにフォローしてくれたユーザーについては、相手のアカウントを積極的にフォローするようにしましょう。
              </p>
              <p>
                「フォロー返し」はユーザーの好感度を高めながら、フォロー解除されにくい状況を作り出すことができます。
              </p>
              <h2 id="id5">コミュニケーションの内容はどうすれば？</h2>
              <p>
                コミュニケーション方法が分かったところで、次に気になるのは実際のツイート内容。顧客へどんなメッセージを送るべきなのか、今すぐ実践できる簡単な方法をご紹介します。
              </p>
              <h3 id="id6">1.Twitterでも基本は「定期的なあいさつ」 </h3>
              <p>
                リアルな会話と同じように、Twitterでも毎日のあいさつが重要です。
                <br />
                「おはようございます」「お疲れ様でした」などのあいさつを、毎日時間を決めて定期的に投稿するようにしましょう。基本のあいさつに、気温や季節のことなどを一言添える程度でもOKです。
              </p>
              <p>
                投稿する時間帯は、多くの人が通勤時に携帯を見る朝7〜8時と、夜の19〜20時がおすすめです。この時間で毎日ツイートすると、ユーザーへの「単純接触効果」で親近感が少しずつ湧くようになります。
              </p>
              <h3 id="id7">2.商品紹介は多くても1日に1回まで </h3>
              <p>
                商品紹介の投稿は、しつこくならない程度に1日1回までにしましょう。
                <br />
                商品やサービスを使った便利な活用方法を紹介するなど、単なるPRにならないように工夫すると、さらにエンゲージメントが高まります。
              </p>
              <p>
                夜の20〜22時はユーザーの投稿やツイートが増える時間帯なので、その時間に商品紹介の投稿をすることで多くの人に見てもらうことができます。
              </p>
              <h3 id="id8">3.ユーザーの役に立つ投稿を心掛ける </h3>
              <p>
                ユーザーの好感度を高めるために大切なのは、ユーザーの「役に立つ」情報を積極的に投稿することです。
                <br />
                例えば、食品を販売していれば「こんな風に使うと意外と美味しい」など、従来の説明にはない楽しみ方を紹介することで、ユーザーの好奇心を刺激することができます。
              </p>
              <p>
                他にも「商品名」についての投稿は、ユーザーの「知りたい」という欲求に働きかけられるのでおすすめです。
                <br />
                例えば、「自社の商品と同じ名前で別のものがあるんです」といった内容です。「最初はこんな名前を考えていました」など商品開発秘話として紹介するのも良いでしょう。
              </p>
              <p>
                商品開発の裏話をつたえるときには、「パッケージ案がこんなにあった」という風に画像も一緒に投稿するとユーザーの反応率が高まります。それに加えてテキストで「皆さんはどれが良いと思いますか？」などと呼びかけて、コミュニケーションにつながる仕掛けを作るとより良いでしょう。
              </p>
              <h3 id="id9">4.言葉遣いはケースバイケース </h3>
              <p>
                ユーザーと話すときの言葉遣いについても、リアルな接客と同じようにケースバイケースの方が人間味が出て好感度が高まります。相手がくだけている時にかたすぎる言葉遣いは、円滑なコミュニケーションを邪魔してしまいます。
              </p>
              <p>
                ただし、「お客様」という意識は忘れずに「最低限の敬語は守る」など一定のルールは守るようにしましょう。リアルな接客を思い浮かべれば、顧客との距離感が分かりやすいと思います。
              </p>
              <h2 id="id10">キャンペーンで参加者とコミュニケーションする</h2>
              <p>
                アカウント立ち上げ直後や、今まで積極的に投稿してこなかった場合には、フォロワー数が少なくてユーザーの反応がほとんどありません。
                <br />
                それに伴って、SNS担当者のモチベーションも下がってしまいます。
                <br />
                アカウント開設から1ヶ月は投稿を続けたものの、Twitter運用自体をフェードアウトしてしまっている企業が多々見受けられます。
              </p>
              <h3 id="id11">
                Twitterプレゼント企画でまずは1万フォロワーを目指そう
              </h3>
              <p>
                店舗・企業のアカウントにとっては、フォロワー数1万人が一つの指標になっており、それを達成することで社会的な信頼感を得られます。
                <br />
                その数字は大きいように見えますが、Twitterでのプレゼントキャンペーンを開催することで十分達成できます。自社の商品がある場合には、2週間で5,000人もフォロワーが増えた事例があります。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </p>
              </div>
              <h3 id="id12">
                プレゼント企画はユーザーとの会話のきっかけづくりにも効果的
              </h3>
              <p>
                また、広告に比べてキャンペーンは、リプライやリツイートでユーザーとコミュニケーションが取りやすくなっています。例えば、参加者から当選報告のツイートがあれば、その返信でコミュニケーションの機会が増えます。
              </p>
              <p>
                広告自体にネガティブなイメージを持っている人もいるため、プレゼントキャンペーンの方が好意的に受け取られやすいといえるでしょう。
              </p>
              <h2 id="id13">Twitterキャンペーンを低コストで始める方法</h2>
              <p>
                フォロワーを増やすために広告を利用する場合、莫大な費用が必要になります。それに引き換え、PARKLoT
                でTwitterキャンペーンを開催する場合、月額5万円〜というリーズナブルな費用で気軽に始められます。
              </p>
              <p>
                しかも、毎月定額の料金体系なので一か月に何度でもキャンペーンを開催できます。頻繁にTwitterキャンペーンを開催できるので、ユーザーにフォローし続けてもらえます。
              </p>
              <p>
                また、参加できるキャンペーンを毎日開催することで、顧客との接点が自然と増えます。毎日Twitterを開いて公式アカウントをチェックするため、「単純接触効果」で好感度が高まっていくのです。
              </p>
              <p>
                当選や落選を全てDM送信できるなど、PARKLoTは便利な機能が使い放題です！
              </p>
            </div>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                  className="m-auto"
                />
              </div>
            </Link>
            <p>
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                お得にTwitterキャンペーンをはじめる！資料ダウンロードはこちら
              </a>
            </p>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="junko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default EffectiveCommunication
